{
  "nm": "spinner",
  "ddd": 0,
  "h": 50,
  "w": 50,
  "meta": { "g": "LottieFiles AE 0.1.20" },
  "layers": [
    {
      "ty": 4,
      "nm": "Shape Layer 1",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 360, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 70 },
            { "s": [0], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "Shape Layer 2",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 405, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 70 },
            { "s": [10], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 2
    },
    {
      "ty": 4,
      "nm": "Shape Layer 3",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 450, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 70 },
            { "s": [20], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 3
    },
    {
      "ty": 4,
      "nm": "Shape Layer 4",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 495, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 70 },
            { "s": [30], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4
    },
    {
      "ty": 4,
      "nm": "Shape Layer 5",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 540, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 70 },
            { "s": [40], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 5
    },
    {
      "ty": 4,
      "nm": "Shape Layer 6",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 585, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 70 },
            { "s": [50], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6
    },
    {
      "ty": 4,
      "nm": "Shape Layer 7",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 630, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 70 },
            { "s": [60], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 7
    },
    {
      "ty": 4,
      "nm": "Shape Layer 8",
      "sr": 1,
      "st": 0,
      "op": 120,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [24.688, 24.938, 0], "ix": 2 },
        "r": { "a": 0, "k": 675, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [70], "t": 0 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [60], "t": 10 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [50], "t": 20 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [40], "t": 30 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [30], "t": 40 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [20], "t": 50 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [10], "t": 60 },
            { "o": { "x": 0.167, "y": 0.167 }, "i": { "x": 0.833, "y": 0.833 }, "s": [0], "t": 70 },
            { "s": [70], "t": 80 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Rectangle 1",
          "ix": 1,
          "cix": 2,
          "np": 3,
          "it": [
            {
              "ty": "rc",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Rect",
              "nm": "Rectangle Path 1",
              "d": 1,
              "p": { "a": 0, "k": [0, -4], "ix": 3 },
              "r": { "a": 0, "k": 15, "ix": 4 },
              "s": { "a": 0, "k": [4, 12], "ix": 2 }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.298, 0.7333, 0.6314], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0.248, -12.664], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 8
    }
  ],
  "v": "5.5.7",
  "fr": 60,
  "op": 79,
  "ip": 0,
  "assets": []
}
