{
  "v": "5.5.7",
  "meta": { "g": "LottieFiles AE 0.1.21", "a": "Keikhosrow HN", "k": "", "d": "Vatandar", "tc": "none" },
  "fr": 60,
  "ip": 0,
  "op": 61,
  "w": 128,
  "h": 128,
  "nm": "Success",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Success Icon",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [64, 64, 0], "ix": 2 },
        "a": { "a": 0, "k": [52, 52, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-18, 1.5],
                    [-7, 12.5],
                    [18, -12.5]
                  ],
                  "o": [
                    [-18, 1.5],
                    [-7, 12.5],
                    [18, -12.5]
                  ],
                  "v": [
                    [-18, 1.5],
                    [-7, 12.5],
                    [18, -12.5]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tm",
              "s": { "a": 0, "k": 0, "ix": 1 },
              "e": {
                "a": 1,
                "k": [
                  { "i": { "x": [0.11], "y": [1] }, "o": { "x": [0.33], "y": [0] }, "t": 0, "s": [0] },
                  { "t": 60.060546875, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 6, "ix": 5 },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [52, 52.5], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "V",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [14.497, -46.5],
                    [-46.5, -25.572],
                    [-25.572, 46.5],
                    [46.5, 25.571],
                    [42.574999999999996, -21.278]
                  ],
                  "o": [
                    [-25.572, -46.5],
                    [-46.5, 25.571],
                    [25.571, 46.5],
                    [46.5, -11.074],
                    [27.501999999999995, -39.772999999999996]
                  ],
                  "v": [
                    [0, -46.5],
                    [-46.5, 0],
                    [0, 46.5],
                    [46.5, 0],
                    [36.047, -29.288]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 6, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [51, 52], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Circle",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 180.18018018018,
      "st": 0,
      "bm": 0,
      "completed": true
    }
  ],
  "markers": [],
  "__complete": true
}
